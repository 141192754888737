import { createSlice } from '@reduxjs/toolkit';

export const PageSlice = createSlice({
  name: 'page',
  initialState: {
    isLoading: false,
    selectedValues: {},
    selects: {},
    qrScans: {},
    locations: {},
    modal: null,
    paymentModal: null,
    orderModal: null,
  },
  reducers: {
    changeIsLoading: (state, action) => {
      state.isLoading = typeof action.payload === 'boolean' ? action.payload : false;
    },
    createSelect: (state, action) => {
      const requiredKeys = ['id', 'select'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        state.selects[action.payload.id] = action.payload.select;
      }
    },
    changeSelect: (state, action) => {
      const requiredKeys = ['id', 'select'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey)) && state.selects.hasOwnProperty(action.payload.id)) {
        state.selects[action.payload.id] = { ...state.selects[action.payload.id], ...action.payload.select };
      }
    },
    removeSelect: (state, action) => {
      const requiredKeys = ['id'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        delete state.selects[action.payload.id];
      }
    },
    createQrScan: (state, action) => {
      const requiredKeys = ['id', 'qrScan'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        state.qrScans[action.payload.id] = action.payload.qrScan;
      }
    },
    changeQrScan: (state, action) => {
      const requiredKeys = ['id', 'qrScan'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey)) && state.qrScans.hasOwnProperty(action.payload.id)) {
        state.qrScans[action.payload.id] = { ...state.qrScans[action.payload.id], ...action.payload.qrScan };
      }
    },
    removeQrScan: (state, action) => {
      const requiredKeys = ['id'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        delete state.qrScans[action.payload.id];
      }
    },
    createLocation: (state, action) => {
      const requiredKeys = ['id', 'location'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        state.locations[action.payload.id] = action.payload.location;
      }
    },
    changeLocation: (state, action) => {
      const requiredKeys = ['id', 'location'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey)) && state.locations.hasOwnProperty(action.payload.id)) {
        state.locations[action.payload.id] = { ...state.locations[action.payload.id], ...action.payload.location };
      }
    },
    removeLocation: (state, action) => {
      const requiredKeys = ['id'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        delete state.locations[action.payload.id];
      }
    },
    changeModal: (state, action) => {
      state.modal = action.payload;
    },
    changePaymentModal: (state, action) => {
      state.paymentModal = action.payload;
    },
    changeOrderModal: (state, action) => {
      state.orderModal = action.payload;
    }
  },
});

export const {
  changeIsLoading,
  createSelect,
  changeSelect,
  removeSelect,
  createQrScan,
  changeQrScan,
  removeQrScan,
  createLocation,
  changeLocation,
  removeLocation,
  changeModal,
  changePaymentModal,
  changeOrderModal
} = PageSlice.actions;

export default PageSlice.reducer;
