import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useTheme, List } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

import Col from '../atoms/layouts/Col';
import Row from '../atoms/layouts/Row';
import View from '../atoms/layouts/View';
import { changeSelect } from '../../redux/reducers/PageReducer';
import SearchInput from '../molecules/inputs/SearchInput';

function Select(props) {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const selects = useSelector((state) => state.page.selects);
  const theme = useTheme();
  const selectId = props.route.params.id;
  const value = selects.hasOwnProperty(selectId) && selects[selectId].hasOwnProperty('value') ? selects[selectId].value : null;
  const options = selects.hasOwnProperty(selectId) && selects[selectId].hasOwnProperty('options') ? selects[selectId].options : [];
  const enableSearch = selects.hasOwnProperty(selectId) && selects[selectId].hasOwnProperty('enableSearch') ? selects[selectId].enableSearch : false;
  const enableMultiple = selects.hasOwnProperty(selectId) && selects[selectId].hasOwnProperty('enableMultiple') ? selects[selectId].enableMultiple : false;
  const [searchString, setSearchString] = useState('');
  const [foundOptions, setFoundOptions] = useState(options);
  const onPress = function (newValue) {
    if (enableMultiple) {
      if (Array.isArray(value) === true) {
        if (value.includes(newValue)) {
          const newArrayValue = value.filter((item) => item !== newValue);
          dispatch(changeSelect({
            id: selectId,
            select: {
              value: newArrayValue,
            },
          }));
        }
        else {
          dispatch(changeSelect({
            id: selectId,
            select: {
              value: [
                ...value,
                newValue
              ],
            },
          }));
        }
      }
      else {
        dispatch(changeSelect({
          id: selectId,
          select: {
            value: [newValue],
          },
        }));
      }
    }
    else {
      dispatch(changeSelect({
        id: selectId,
        select: {
          value: newValue,
        },
      }));
      setTimeout(() => {
        navigation.goBack();
      }, 200);
    }
  };
  const styles = StyleSheet.create({
    selectedOptionContainer: {
      borderWidth: 1,
      borderColor: theme.colors.primary,
    },
  });

  return (
    <View>
      { enableSearch === true ? (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <SearchInput
              value={searchString}
              onChange={(searchString) => {
                setSearchString(searchString);
                const foundOptions = [];
                options.forEach((option) => {
                  if (typeof searchString === 'string' && option.label.toUpperCase().includes(searchString.toUpperCase())) {
                    foundOptions.push(option);
                  }
                });
                if (foundOptions.length > 0) {
                  setFoundOptions(foundOptions);
                }
              }}
            />
          </Col>
        </Row>
      ) : null }
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          { foundOptions.map((option, optionKey) => (
            <List.Item
              key={optionKey}
              title={option.label}
              description={option.hasOwnProperty('description') ? option.description : null}
              icon={option.hasOwnProperty('description') ? option.description : null}
              left={option.hasOwnProperty('icon') ? function (props) {
                return <List.Icon {...props} icon={option.icon} />;
              } : null}
              right={enableMultiple === true ? (Array.isArray(value) === true && value.includes(option.value) ? (props) => {
                return <List.Icon {...props} icon="star" color={theme.colors.primary} />;
              } : null) : (option.value === value ? (props) => {
                return <List.Icon {...props} icon="star" color={theme.colors.primary} />;
              } : null) }
              onPress={() => {
                if (enableMultiple !== true) {
                  setFoundOptions([]);
                }
                setSearchString('');
                onPress(option.value);
              }}
              style={enableMultiple === true ? (Array.isArray(value) === true && value.includes(option.value) ? styles.selectedOptionContainer : []) : (option.value === value ? styles.selectedOptionContainer : [])}
            />
          )) }
        </Col>
      </Row>
    </View>
  );
}

export default Select;
