import * as DefaultLinking from 'expo-linking';
import Config from '../Config';

const Linking = {
  prefixes: [Config.baseUrl, Config.protocolId, DefaultLinking.createURL('/')],
  config: {
    screens: {
      Login: {
        path: '/login',
      },
      Register: {
        path: '/register',
      },
      Reset: {
        path: '/reset',
      },
      Home: {
        screens: {
          Dashboard: {
            path: '/',
          },
          Select: {
            path: '/select/:id'
          },
          Location: {
            path: '/location/:id',
          },
          QrScan: {
            path: '/qrScan/:id',
          },
          OrderCreateItem: {
            path: '/order/new',
          },
          ProductGetItem: {
            path: '/product',
          }
        }
      },
      Account: {
        screens: {
          MyAccount: {
            path: '/my/account',
          },
          MyAccountUpdate: {
            path: '/my/account/update',
          }
        }
      },
      Cart: {
        screens: {
          MyCart: {
            path: '/my/cart',
          }
        }
      },
      Orders: {
        screens: {
          MyOrders: {
            path: '/my/orders',
          }
        }
      }
    },
  },
};

export default Linking;
