import React, { useEffect, useState } from 'react';
import {Title, Button} from 'react-native-paper';
import { BarCodeScanner, PermissionStatus } from 'expo-barcode-scanner';
import Translator from "../../../modules/Translator";
import {prepareLocale} from "../../../modules/Helper";
import {useSelector} from "react-redux";
import { View, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Camera } from 'expo-camera';
import { useIsFocused } from '@react-navigation/native';

function QrCodeScanner(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const [cameraPermissions, setCameraPermissions] = useState(null);
  const [scanned, setScanned] = useState(false);
  const isFocused = useIsFocused();
  const onScanned = props.hasOwnProperty('onScanned') ? props.onScanned : null;
  const style = props.hasOwnProperty('style') ? props.style : {};
  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    onScanned(data, type);
  };
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    inside: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0.3
    },
    insideIcon: {
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: '#ffffff',
      borderRadius: 10
    },
    button: {
      borderRadius: 200
    },
    buttonContent: {
      width: 120,
      height: 120
    }
  });

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setCameraPermissions(status);
    })();
  }, []);

  if (cameraPermissions === PermissionStatus.DENIED) {
    return (
      <Title>{ Translator.t('components.atoms.elements.qrCodeScanner.permissions.denied') }</Title>
    );
  }

  if (cameraPermissions === PermissionStatus.UNDETERMINED) {
    return (
      <Title>{ Translator.t('components.atoms.elements.qrCodeScanner.permissions.undetermined') }</Title>
    );
  }

  if (!isFocused) {
    return null;
  }

  return (
    <View style={[styles.container, style]}>
      <Camera
        onBarCodeScanned={scanned ? null : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
        }}
      >
        <View style={styles.inside}>
          { scanned === false ? (
            <MaterialCommunityIcons name="qrcode" size={250} color={ '#ffffff' } style={styles.insideIcon} />
          ) : null }
        </View>
      </Camera>
      { scanned && <Button contentStyle={styles.buttonContent} style={styles.button} mode='contained' onPress={() => setScanned(false)}>
        <MaterialCommunityIcons name="refresh" size={50} color={ '#ffffff' } />
      </Button> }
    </View>
  );
}

export default QrCodeScanner;
