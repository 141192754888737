import React from 'react';
import {StyleSheet, View} from "react-native";
import {Text, Title, useTheme} from "react-native-paper";

import Chip from "../elements/Chip";

function TagsFilter(props) {
  const theme = useTheme();
  const tags = props.hasOwnProperty('tags') && typeof props.tags === 'object' ? props.tags : {};
  const onPress = props.hasOwnProperty('onPress') ? props.onPress : null;
  const styles = StyleSheet.create({
    chipContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    selectedChipText: {
      color: theme.colors.primary
    }
  });

  return (
    <View style={styles.chipContainer}>
      {
        Object.keys(tags).length === 0 ? (<Title>---</Title>) : null
      }
      {Object.keys(tags).map((tag, tagKey) => (
        <Chip key={tagKey} size={30} textStyle={tags[tag] ? styles.selectedChipText : []} onPress={onPress !== null ? function () {
          onPress(tag);
        } : null}>
          <Text style={tags[tag] ? styles.selectedChipText : []}>
            #
            {tag}
          </Text>
        </Chip>
      ))}
    </View>
  );
}

export default TagsFilter;
