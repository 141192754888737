import React, {useEffect, useState} from 'react';
import { Platform } from 'react-native';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector } from 'react-redux';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import Translator from '../modules/Translator';
import Header from './atoms/elements/Header';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import Reset from './pages/Reset';
import Select from './pages/Select';
import Location from './pages/Location';
import MyAccount from './pages/MyAccount';
import MyAccountUpdate from './pages/MyAccountUpdate';
import QrScan from './pages/QrScan';
import OrderCreateItem from './pages/OrderCreateItem';
import ProductGetItem from './pages/ProductGetItem';
import { prepareLocale } from '../modules/Helper';
import {useTheme} from "react-native-paper";
import MyCart from "./pages/MyCart";
import MyOrders from "./pages/MyOrders";

const TabNavigator = createMaterialBottomTabNavigator();
const StackNavigator = createStackNavigator();

function HomeStackNavigator() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerShown: true,
        header: (props) => <Header {...props} />,
        headerMode: Platform.OS === 'web' ? 'screen' : 'float'
      }}
    >
      <StackNavigator.Screen
        name="Dashboard"
        component={Dashboard}
        options={() => ({
          title: Translator.t('pages.Dashboard.title'),
        })}
      />
      <StackNavigator.Screen
        name="OrderCreateItem"
        component={OrderCreateItem}
        options={() => ({
          title: Translator.t('pages.OrderCreateItem.title'),
        })}
      />
      <StackNavigator.Screen
        name="ProductGetItem"
        component={ProductGetItem}
        options={() => ({
          title: Translator.t('pages.ProductGetItem.title'),
        })}
      />
      <StackNavigator.Screen
        name="Select"
        component={Select}
        options={() => ({
          title: Translator.t('pages.Select.title')
        })}
      />
      <StackNavigator.Screen
        name="Location"
        component={Location}
        options={() => ({
          title: Translator.t('pages.Location.title')
        })}
      />
      <StackNavigator.Screen
        name="QrScan"
        component={QrScan}
        options={() => ({
          title: Translator.t('pages.QrScan.title')
        })}
      />
    </StackNavigator.Navigator>
  );
}

function AccountStackNavigator() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerShown: true,
        header: (props) => <Header {...props} />,
        headerMode: Platform.OS === 'web' ? 'screen' : 'float'
      }}
    >
      <StackNavigator.Screen
        name="MyAccount"
        component={MyAccount}
        options={() => ({
          title: Translator.t('pages.MyAccount.title'),
        })}
      />
      <StackNavigator.Screen
        name="MyAccountUpdate"
        component={MyAccountUpdate}
        options={() => ({
          title: Translator.t('pages.MyAccountUpdate.title'),
        })}
      />
    </StackNavigator.Navigator>
  );
}

function CartStackNavigator() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerShown: true,
        header: (props) => <Header {...props} />,
        headerMode: Platform.OS === 'web' ? 'screen' : 'float'
      }}
    >
      <StackNavigator.Screen
        name="MyCart"
        component={MyCart}
        options={() => ({
          title: Translator.t('pages.MyCart.title'),
        })}
      />
    </StackNavigator.Navigator>
  );
}

function OrdersStackNavigator() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerShown: true,
        header: (props) => <Header {...props} />,
        headerMode: Platform.OS === 'web' ? 'screen' : 'float'
      }}
    >
      <StackNavigator.Screen
        name="MyOrders"
        component={MyOrders}
        options={() => ({
          title: Translator.t('pages.MyOrders.title'),
        })}
      />
    </StackNavigator.Navigator>
  );
}

function MainTabNavigation() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const isLoggedIn = useSelector((state) => state.auth.user) !== null;
  const activeOrder = useSelector((state) => state.session.activeOrder);
  const orders = useSelector((state) => state.session.orders);
  const theme = useTheme();
  const [countOfActiveOrderProducts, setCountOfActiveOrderProducts] = useState(null);
  const [countOfOrders, setCountOfOrders] = useState(null);

  useEffect(() => {
    setCountOfActiveOrderProducts(null);
    if (activeOrder !== null) {
      activeOrder.products.forEach((activeOrderProduct) => {
        const count = activeOrderProduct.hasOwnProperty('count') ? activeOrderProduct.count : 1;
        setCountOfActiveOrderProducts((countOfActiveOrders) => {
          return countOfActiveOrders + count;
        });
      });
    }
  }, [activeOrder]);

  useEffect(() => {
    setCountOfOrders(null);
    if (orders !== null && orders.length > 0) {
      setCountOfOrders(orders.length);
    }
  }, [orders]);

  if (!isLoggedIn) {
    return (
      <StackNavigator.Navigator
        screenOptions={{
          headerShown: true,
          header: (props) => <Header {...props} />,
          headerMode: Platform.OS === 'web' ? 'screen' : 'float'
        }}
      >
        <StackNavigator.Screen
          name="Login"
          component={Login}
          options={() => ({
            title: Translator.t('pages.Login.title'),
          })}
        />
        <StackNavigator.Screen
          name="Register"
          component={Register}
          options={() => ({
            title: Translator.t('pages.Register.title'),
          })}
        />
        <StackNavigator.Screen
          name="Reset"
          component={Reset}
          options={() => ({
            title: Translator.t('pages.Reset.title'),
          })}
        />
      </StackNavigator.Navigator>
    );
  }

  return (
    <TabNavigator.Navigator shifting={true} activeColor={theme.colors.primary} barStyle={{
      backgroundColor: theme.colors.surface
    }}>
      <TabNavigator.Screen name={'Home'} component={HomeStackNavigator} options={{
        tabBarLabel: Translator.t('pages.Dashboard.title'),
        tabBarIcon: (props) => <MaterialCommunityIcons name="home" size={24} {...props} />
      }}/>
      <TabNavigator.Screen name={'Account'} component={AccountStackNavigator} options={{
        tabBarLabel: Translator.t('pages.MyAccount.title'),
        tabBarIcon: (props) => <MaterialCommunityIcons name="account" size={24} {...props} />
      }}/>
      <TabNavigator.Screen name={'Cart'} component={CartStackNavigator} options={{
        tabBarLabel: Translator.t('pages.MyCart.title'),
        tabBarBadge: countOfActiveOrderProducts,
        tabBarIcon: (props) => <MaterialCommunityIcons name="cart" size={24} {...props} />
      }}/>
      <TabNavigator.Screen name={'Orders'} component={OrdersStackNavigator} options={{
        tabBarLabel: Translator.t('pages.MyOrders.title'),
        tabBarBadge: countOfOrders,
        tabBarIcon: (props) => <MaterialCommunityIcons name="food" size={24} {...props} />
      }}/>
    </TabNavigator.Navigator>
  );
}

export default MainTabNavigation;
