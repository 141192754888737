import React from 'react';
import { useSelector } from 'react-redux';

import QrScanInput from '../../atoms/inputs/QrScanInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function QrCodeInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <QrScanInput
      label={Translator.t('components.molecules.inputs.QrCodeInput.label')}
      {...props}
    />
  );
}

export default QrCodeInput;
