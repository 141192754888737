import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Headline, Caption, Paragraph, Divider } from 'react-native-paper';

import Col from './Col';

function Section(props) {
  const styles = StyleSheet.create({
    container: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0
    },
    headingContainer: {
      paddingTop: 0,
      paddingBottom: props.hasOwnProperty('children') && props.children !== null ? 25 : 0,
      paddingLeft: 5,
      paddingRight: 5,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    headingContainerContent: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    titleContainer: {
      paddingTop: 0,
      paddingBottom: props.hasOwnProperty('description') ? 5 : 0,
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0
    },
    descriptionContainer: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0
    },
    contentContainer: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0
    },
    divider: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 25,
      marginBottom: 25,
      marginLeft: 0,
      marginRight: 0
    }
  });

  if (props.hasOwnProperty('hidden') && props.hidden === true) {
    return null;
  }

  return (
    <Col style={styles.container} xs={typeof props.xs !== 'undefined' ? props.xs : 12} sm={typeof props.sm !== 'undefined' ? props.sm : 12} md={typeof props.md !== 'undefined' ? props.md : 12} lg={typeof props.lg !== 'undefined' ? props.lg : 12}>
      <View style={styles.headingContainer}>
        <View style={styles.headingContainerContent}>
          <View>
            <Headline style={styles.titleContainer}>{props.title}</Headline>
            { props.hasOwnProperty('description') ? <Caption style={styles.descriptionContainer}>{props.description}</Caption> : null }
          </View>
          {
            props.hasOwnProperty('headingShortcut') ? props.headingShortcut : null
          }
        </View>
        <View>
          {
            props.hasOwnProperty('headingContent') ? props.headingContent : null
          }
        </View>
      </View>
      {
        props.hasOwnProperty('children') && props.children !== null ? (
          <View style={styles.contentContainer}>
            {props.children}
          </View>
        ) : null
      }
      { props.hasOwnProperty('hideBottomDivider') && props.hideBottomDivider === true ? null : <Divider style={styles.divider}/> }
    </Col>
  );
}

export default Section;
