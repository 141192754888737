import { createSlice } from '@reduxjs/toolkit';

export const SessionSlice = createSlice({
  name: 'session',
  initialState: {
    activeOrder: null,
    orders: []
  },
  reducers: {
    changeActiveOrder: (state, action) => {
      state.activeOrder = action.payload;
    },
    changeOrders: (state, action) => {
      state.orders = action.payload;
    },
  },
});

export const {
  changeActiveOrder,
  changeOrders
} = SessionSlice.actions;

export default SessionSlice.reducer;
