import React, { useEffect, useState } from 'react';
import Firebase from "../../modules/Firebase";
import Translator from "../../modules/Translator";
import {parseDocPath} from "../../modules/Helper";
import DashboardLayout from "../atoms/layouts/DashboardLayout";
import Col from "../atoms/layouts/Col";
import Row from "../atoms/layouts/Row";
import View from "../atoms/layouts/View";
import Section from "../atoms/layouts/Section";
import TagsView from "../atoms/elements/TagsView";
import Card from "../atoms/elements/Card";

function ProductGetItem(props) {
  const [product, setProduct] = useState(null);

  const productDocPath = typeof props.route.params === 'object' && props.route.params.hasOwnProperty('path') && typeof props.route.params.path === 'string' ? props.route.params.path : null;
  if (productDocPath === null) {
    alert(Translator.t('pages.ProductGetItem.error.invalidProduct'));
    return null;
  }

  const productDocParsedPath = parseDocPath(productDocPath);
  if (!productDocParsedPath.hasOwnProperty('product')) {
    alert(Translator.t('pages.ProductGetItem.error.invalidProduct'));
    return null;
  }

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('product').doc(productDocParsedPath.product).onSnapshot((productDoc) => {
      if (productDoc.exists) {
        const product = productDoc.data();
        setProduct({
          ...product,
          productId: productDoc.id
        });
      }
      else {
        alert(Translator.t('pages.ProductGetItem.error.invalidProduct'));
        return null;
      }
    });
    return () => unsubscribe();
  }, []);

  if (product === null) {
    return null;
  }

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Section title={Translator.t('pages.ProductGetItem.content.generalInformation.title')} description={Translator.t('pages.ProductGetItem.content.generalInformation.description')} hideBottomDivider={true}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card cover={product.image} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.ProductGetItem.content.generalInformation.tags.title')} subtitle={Translator.t('pages.ProductGetItem.content.generalInformation.tags.description')} icon="tag">
                  <TagsView tags={product.tags}/>
                </Card>
              </Col>
            </Row>
          </Section>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default ProductGetItem;
