import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { capitalize } from 'stringulation';
import {
  getLastUpdateDateOfOrder, outputExchangedPrice,
  outputPrice,
  parseDocPath
} from "../../modules/Helper";
import Row from "../atoms/layouts/Row";
import Col from "../atoms/layouts/Col";
import Footer from "../atoms/layouts/Footer";
import DashboardLayout from "../atoms/layouts/DashboardLayout";
import Section from "../atoms/layouts/Section";
import Translator from "../../modules/Translator";
import {Divider, IconButton, Menu, Title, useTheme} from "react-native-paper";
import ProductList from "../organisms/lists/ProductList";
import View from "../atoms/layouts/View";
import {StyleSheet, View as DefaultView} from "react-native";
import {changePaymentModal} from "../../redux/reducers/PageReducer";
import Config from "../../Config";

function MyOrders(props) {
  const dispatch = useDispatch();
  const sessionOrders = useSelector((state) => state.session.orders);
  const [orders, setOrders] = useState([]);
  const [isOrderMenuOpened, setIsOrderMenuOpened] = useState({});
  const theme = useTheme();
  const openSectionShortcutsMenu = (path) => {
    setIsOrderMenuOpened((isOrderMenuOpened) => {
      const newIsOrderMenuOpened = Object.assign({}, isOrderMenuOpened);
      if (newIsOrderMenuOpened.hasOwnProperty(path)) {
        newIsOrderMenuOpened[path] = !(newIsOrderMenuOpened[path]);
      }
      else {
        newIsOrderMenuOpened[path] = true;
      }
      return newIsOrderMenuOpened;
    });
  };
  const closeSectionShortcutsMenu = (path) => {
    setIsOrderMenuOpened((isOrderMenuOpened) => {
      const newIsOrderMenuOpened = Object.assign({}, isOrderMenuOpened);
      if (newIsOrderMenuOpened.hasOwnProperty(path)) {
        newIsOrderMenuOpened[path] = !(newIsOrderMenuOpened[path]);
      }
      else {
        newIsOrderMenuOpened[path] = false;
      }
      return newIsOrderMenuOpened;
    });
  };
  const payOrder = (order) => {
    dispatch(changePaymentModal({
      order,
      step: 1,
    }));
  };
  const styles = StyleSheet.create({
    orderSectionShortcutsContainer: {
      marginTop: 50
    },
    sectionHeadingContentContainer: {
      marginTop: 10
    }
  });

  useEffect(() => {
    if (sessionOrders !== null) {
      const newOrders = [];
      sessionOrders.forEach((order) => {
        newOrders.push(order);
      });
      newOrders.sort((a, b) => {
        return getLastUpdateDateOfOrder(b) - getLastUpdateDateOfOrder(a);
      });
      setOrders(newOrders);
    }
  }, [sessionOrders]);

  return (
    <DashboardLayout>
      <View>
        <Row>
          {
            orders.map((order) => {
              const products = order.order.products.map((product) => {
                return {
                  productId: parseDocPath(product.path).product,
                  ...product,
                  hideCover: true
                };
              });
              const totalPrice = order.order.exchangedPrice;
              return (
                <Section key={order.path} title={order.order.store.name} headingContent={
                  <DefaultView style={styles.sectionHeadingContentContainer}>
                    <Title>
                      {capitalize(Translator.t('words.total'))}: { outputExchangedPrice(totalPrice) }
                    </Title>
                  </DefaultView>
                } headingShortcut={
                  <Menu
                    style={styles.orderSectionShortcutsContainer}
                    visible={isOrderMenuOpened.hasOwnProperty(order.path) && isOrderMenuOpened[order.path] === true}
                    onDismiss={() => (closeSectionShortcutsMenu(order.path))}
                    anchor={<IconButton icon="dots-vertical" color={theme.colors.primary} size={30} onPress={() => (openSectionShortcutsMenu(order.path))} />}>
                    {
                      order.order.status === Config.orderStatuses[1] ? (
                        <Menu.Item icon='cart' onPress={() => {
                          closeSectionShortcutsMenu(order.path);
                          payOrder(order);
                        }} title={capitalize(Translator.t('words.pay'))} />
                      ) : null
                    }
                    <Divider/>
                    <Menu.Item icon='window-close' onPress={() => (openSectionShortcutsMenu(order.path))} title={capitalize(Translator.t('words.close'))} />
                  </Menu>
                }>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <ProductList products={products} />
                  </Col>
                </Section>
              );
            })
          }
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Footer/>
          </Col>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default MyOrders;
