import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Card from '../../atoms/elements/Card';
import Translator from '../../../modules/Translator';
import {
  prepareLocale,
  outputPrice,
  buildDocPath,
  changeTimeZoneOfDate, translateOrderProductStatus, roundPrice
} from '../../../modules/Helper';
import Firebase from "../../../modules/Firebase";
import Config from "../../../Config";

function ProductCard({
                       status, tableDocPath, count, countChangeCallback, cover, avatar, name, description, productId, enableBuy, enableView, customActions, disabled, hideCover, totalPrice, tags
                   }) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const activeOrder = useSelector((state) => state.session.activeOrder);
  const user = useSelector((state) => state.auth.user);
  const navigation = useNavigation();
  enableBuy = typeof enableBuy !== 'undefined' && enableBuy;
  enableView = typeof enableView !== 'undefined' && enableView;
  const totalPriceObject = typeof totalPrice === 'object' ? totalPrice : null;
  const priceOutput = totalPriceObject !== null ? outputPrice(roundPrice(totalPriceObject.price), totalPriceObject.currency) : null;

  const actions = [];

  if (enableBuy) {
    let title = Translator.t('words.buy');
    actions.push({
      title: title,
      mode: 'contained',
      onPress() {
        const path = buildDocPath({
          product: productId,
        });
        let ifInserted = false;
        if (activeOrder !== null) {
          const newActiveOrderProducts = activeOrder.products.map(object => ({ ...object }));
          newActiveOrderProducts.forEach((newActiveOrderProduct, newActiveOrderProductIndex) => {
            const productRef = newActiveOrderProduct.product;
            if (productRef.path === path) {
              const count = newActiveOrderProduct.hasOwnProperty('count') ? newActiveOrderProduct.count + 1 : 1;
              newActiveOrderProducts[newActiveOrderProductIndex] = {
                ...newActiveOrderProducts[newActiveOrderProductIndex],
                count,
                lastAddedAt: changeTimeZoneOfDate(new Date(), Config.systemTimezone),
              };
              ifInserted = true;
            }
          });
          if (ifInserted === false) {
            newActiveOrderProducts.push({
              count: 1,
              product: Firebase.firestore().doc(path),
              table: Firebase.firestore().doc(tableDocPath),
              lastAddedAt: changeTimeZoneOfDate(new Date(), Config.systemTimezone),
            });
          }
          Firebase.firestore().collection('user').doc(user).set({
            activeOrder: {
              ...activeOrder,
              products: newActiveOrderProducts
            }
          }, {
            merge: true
          }).then(() => {
            // alert(Translator.t('components.organisms.items.ProductCard.submit.title'));
          }).catch((error) => {
            alert(error.message);
          });
        }
        else {
          const newActiveOrder = {
            products: [
              {
                count: 1,
                product: Firebase.firestore().doc(path),
                table: Firebase.firestore().doc(tableDocPath),
                lastAddedAt: changeTimeZoneOfDate(new Date(), Config.systemTimezone),
              }
            ]
          };
          Firebase.firestore().collection('user').doc(user).set({
            activeOrder: newActiveOrder
          }, {
            merge: true
          }).then(() => {
            // alert(Translator.t('components.organisms.items.ProductCard.submit.title'));
          }).catch((error) => {
            alert(error.message);
          });
        }
      },
    });
  }

  if (enableView) {
    actions.push({
      title: Translator.t('words.view'),
      mode: 'outlined',
      onPress() {
        navigation.push('ProductGetItem', {
          path: 'product/' + productId
        });
      },
    });
  }

  return (
    <Card tags={tags} status={Config.orderProductStatuses.includes(status) ? translateOrderProductStatus(status, Translator) : null} price={priceOutput} avatar={avatar} count={count} countChangeCallback={countChangeCallback} cover={typeof cover !== 'undefined' && cover !== null ? cover : 'https://firebasestorage.googleapis.com/v0/b/smart-take.appspot.com/o/images%2Fproduct%2Fdefault.jpg?alt=media&token=cc3fd42e-3373-45b6-83a6-6cfb92257ca1'} title={name} subtitle={description} icon="shopping" actions={actions.concat(typeof customActions === 'undefined' ? [] : customActions)} disabled={disabled} hideCover={hideCover} />
  );
}

export default ProductCard;
