import Translator from './Translator';

function translateFirebaseError(code, locale, message) {
  Translator.locale = locale;
  if (!Translator.lookup('firebaseErrors.' + code)) {
    return message;
  }
  else {
    return Translator.t('firebaseErrors.' + code);
  }
}

export { translateFirebaseError };
