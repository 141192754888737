import React from 'react';
import { useSelector } from 'react-redux';
import { Appbar, ProgressBar } from 'react-native-paper';

function Header(props) {
  const isLoading = useSelector((state) => state.page.isLoading);
  const title = typeof props.options.title !== 'undefined' ? props.options.title : props.route.name;

  return (
    <>
      <Appbar.Header>
        {typeof props.progress === 'undefined' || props.progress.previous == null ? null : <Appbar.BackAction onPress={props.navigation.goBack} />}
        <Appbar.Content title={title} />
      </Appbar.Header>
      {isLoading ? <ProgressBar indeterminate /> : <></>}
    </>
  );
}

export default Header;
