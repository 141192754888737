import React from 'react';
import { Title } from 'react-native-paper';
import { useSelector } from 'react-redux';

import Col from '../../atoms/layouts/Col';
import Row from '../../atoms/layouts/Row';
import ProductCard from '../items/ProductCard';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function ProductList({ products, cols }) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  cols = typeof cols === 'undefined' ? 2 : cols;

  if (products.length === 0) {
    return (
      <Row>
        <Col xs={12} sm={12} md={12 / cols} lg={12 / (cols + 1)}>
          <Title>{Translator.t('components.organisms.lists.ProductList.empty.title')}</Title>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      { products.map((product, productKey) => (
        <Col xs={12} sm={12} md={12 / cols} lg={12 / (cols + 1)} key={productKey}>
          <ProductCard {...product} />
        </Col>
      )) }
    </Row>
  );
}

export default ProductList;
