import React from 'react';

import DashboardLayout from "../atoms/layouts/DashboardLayout";
import View from "../atoms/layouts/View";
import Row from "../atoms/layouts/Row";
import Col from "../atoms/layouts/Col";
import OrderCreateForm from "../organisms/forms/OrderCreateForm";
import Footer from "../atoms/layouts/Footer";

function OrderCreateItem(props) {
  const path = typeof props.route.params === 'object' && props.route.params.hasOwnProperty('path') && typeof props.route.params.path === 'string' ? props.route.params.path : null;

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <OrderCreateForm path={path} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Footer/>
          </Col>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default OrderCreateItem;
