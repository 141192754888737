import React from 'react';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import {Avatar, Button, Card as DefaultCard, IconButton, useTheme, Text, Title, Badge} from 'react-native-paper';

import Col from '../layouts/Col';
import Config from '../../../Config';
import TagsView from "./TagsView";
import Row from "../layouts/Row";

function Card(props) {
  const theme = useTheme();
  const icon = props.hasOwnProperty('icon') && typeof props.icon === 'string' ? props.icon : null;
  const avatar = props.hasOwnProperty('avatar') && typeof props.avatar === 'string' ? props.avatar : null;
  const actions = props.hasOwnProperty('actions') && Array.isArray(props.actions) ? props.actions : [];
  const content = props.hasOwnProperty('children') && typeof props.children !== 'undefined' ? props.children : null;
  const count = props.hasOwnProperty('count') && typeof props.count !== 'undefined' ? props.count : null;
  const countChangeCallback = props.hasOwnProperty('countChangeCallback') && typeof props.countChangeCallback !== 'undefined' ? props.countChangeCallback : null;
  const price = props.hasOwnProperty('price') && props.price !== null ? props.price : null;
  const tags = props.hasOwnProperty('tags') && props.tags !== null ? props.tags : [];
  const priceAsStatus = props.hasOwnProperty('priceAsStatus') && props.priceAsStatus !== null ? props.priceAsStatus : null;
  const disabled = props.hasOwnProperty('disabled') && props.disabled === true;
  const hideCover = props.hasOwnProperty('hideCover') && props.hideCover === true;
  const status = props.hasOwnProperty('status') ? props.status : null;
  const leftContent = function (props) {
    if (avatar !== null) {
      return <Avatar.Image {...props} source={{ uri: avatar }} />;
    }
    if (icon !== null) {
      return <Avatar.Icon {...props} icon={icon} />;
    }
    return null;
  };
  const styles = StyleSheet.create({
    card: {
      flex: 1,
      height: Dimensions.get('window').width >= Config.dimensions.md ? '100%' : null,
      minHeight: Dimensions.get('window').width >= Config.dimensions.md ? ( actions.length > 0 ? 120 : null ) : null,
    },
    titleRow: {
      paddingLeft: 16,
      paddingRight: 16
    },
    buttonRow: {
      paddingLeft: 16,
      paddingRight: 16
    },
    buttonCol: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 16
    },
    buttonColStart: {
      paddingRight: 2,
    },
    buttonColMiddle: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    buttonColEnd: {
      paddingLeft: 2,
    },
    content: {
      paddingTop: props.hasOwnProperty('subtitle') ? 10 : 0,
      paddingBottom: 16,
      paddingLeft: 72,
      paddingRight: 0,
      marginTop: -15,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0
    },
    count: {
      position: "absolute",
      top: -7,
      right: -7,
      zIndex: 999
    },
    countContainer: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
    countContainerContent: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      backgroundColor: theme.colors.background,
      borderRadius: 20
    },
    countContainerCount: {
      backgroundColor: theme.colors.background
    },
    disabledContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    disabledCard: {
      opacity: 0.3
    },
    statusContainer: {
      position: "absolute",
      top: -5,
      right: -5,
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: theme.colors.primary
    },
    priceAsStatusContainer: {
      position: "absolute",
      top: -5,
      right: -5,
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: theme.colors.primary
    },
    priceTagsContainer: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: 16,
      paddingRight: 16,
    },
    priceTagsContainerCol: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    priceTagsContainerTagsContainer: {
    },
    priceTagsContainerPriceContainer: {
    },
    priceTagsContainerPriceContainerPrice: {
      textAlign: "right"
    }
  });

  return (
    <DefaultCard mode="elevated" elevation={5} style={disabled ? [styles.card, styles.disabledCard] : styles.card}>
      { hideCover !== true && props.hasOwnProperty('cover') && typeof props.cover !== 'undefined' && props.cover !== null ? (
        <DefaultCard.Cover source={{ uri: props.cover }} />
      ) : <></> }
      {
        status !== null ? (
          <Badge style={styles.statusContainer} size={30}>{ status }</Badge>
        ) : null
      }
      {
        priceAsStatus !== null ? (
          <Badge style={styles.priceAsStatusContainer} size={40}>{ priceAsStatus }</Badge>
        ) : null
      }
      {
        props.hasOwnProperty('title') || props.hasOwnProperty('subtitle') || icon !== null || avatar !== null ? (
          <DefaultCard.Title style={styles.titleRow} title={props.title} subtitle={props.hasOwnProperty('subtitle') ? props.subtitle : null} left={icon !== null || avatar !== null ? leftContent : null} />
        ) : null
      }
      {
        content !== null ? (
          <View style={styles.content}>
            {content}
          </View>
        ) : null
      }
      {
        countChangeCallback !== null ? (
          <View style={[styles.countContainer]}>
            <View style={styles.countContainerContent}>
              <IconButton
                animated={true}
                icon="minus"
                color={theme.colors.primary}
                size={20}
                onPress={disabled ? null : () => countChangeCallback(count - 1)}
                // disabled={count < 2}
              />
              <Avatar.Text style={styles.countContainerCount} size={32} label={count} />
              <IconButton
                animated={true}
                icon="plus"
                color={theme.colors.primary}
                size={20}
                onPress={disabled ? null : () => countChangeCallback(count + 1)}
              />
            </View>
            <View>
              <Title>{ price }</Title>
            </View>
          </View>
        ) : (
          price !== null && tags.length > 0 ? (
            <Row style={[styles.priceTagsContainer]}>
              <Col xs={8} sm={8} md={8} lg={8} style={styles.priceTagsContainerCol}>
                <ScrollView style={styles.priceTagsContainerTagsContainer} horizontal={true} showsHorizontalScrollIndicator={false}>
                  <TagsView tags={tags}/>
                </ScrollView>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} style={styles.priceTagsContainerCol}>
                <View style={styles.priceTagsContainerPriceContainer}>
                  <Title style={styles.priceTagsContainerPriceContainerPrice}>{ price }</Title>
                </View>
              </Col>
            </Row>
          ) : null
        )
      }
      {
        actions.length > 0 ? (
          <View>
            { actions.length === 1 ? (
              <DefaultCard.Actions style={styles.buttonRow}>
                <Col style={styles.buttonCol} xs={12} sm={12} md={12} lg={12}>
                  <Button mode={actions[0].mode} onPress={disabled ? null : actions[0].onPress}>{ actions[0].title }</Button>
                </Col>
              </DefaultCard.Actions>
            ) : <></> }
            { actions.length > 1 ? (
              <DefaultCard.Actions style={styles.buttonRow}>
                { actions.map((action, actionKey) => {
                  const containerStyles = [styles.buttonCol];
                  if (actionKey === 0) {
                    containerStyles.push(styles.buttonColStart);
                  }
                  else if (actionKey === actions.length - 1) {
                    containerStyles.push(styles.buttonColEnd);
                  }
                  else {
                    containerStyles.push(styles.buttonColMiddle);
                  }
                  return (
                    <Col style={containerStyles} xs={12 / actions.length} sm={12 / actions.length} md={12 / actions.length} lg={12 / actions.length} key={actionKey}>
                      <Button mode={action.mode} onPress={disabled ? null : action.onPress}>{ action.title }</Button>
                    </Col>
                  );
                }) }
              </DefaultCard.Actions>
            ) : <></> }
          </View>
        ) : null
      }
      {
        disabled ? (
          <View style={styles.disabledContainer}>
          </View>
        ) : null
      }
    </DefaultCard>
  );
}

export default Card;
