import React, {useEffect, useState} from 'react';
import {StyleSheet} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import {Dialog, RadioButton, useTheme, Button, Caption} from "react-native-paper";
import Translator from "../../../modules/Translator";
import {outputExchangedPriceAndTip, prepareLocale, roundPrice} from "../../../modules/Helper";
import Slider from "../inputs/Slider";
import {capitalize} from "stringulation";
import Col from "../layouts/Col";
import Firebase from "../../../modules/Firebase";
import {changePaymentModal} from "../../../redux/reducers/PageReducer";

function PaymentModal(props) {
  const paymentModal = props.hasOwnProperty('paymentModal') ? props.paymentModal : null;
  const setPaymentModal = props.hasOwnProperty('setPaymentModal') ? props.setPaymentModal : null;
  if (paymentModal === null || setPaymentModal === null || !paymentModal.hasOwnProperty('step') || !paymentModal.hasOwnProperty('order')) {
    return null;
  }
  const currentStepIndex = paymentModal.step;
  const totalPrice = paymentModal.order.order.exchangedPrice;
  const theme = useTheme();
  const dispatch = useDispatch();
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  const [store, setStore] = useState(null);

  const [restaurantSupportedPaymentMethods, setRestaurantSupportedPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const [tipPercentage, setTipPercentage] = useState(0);
  const [tip, setTip] = useState({});

  const steps = {
    1: {
      content: (
        <Dialog.Content>
          <RadioButton.Group onValueChange={newPaymentMethod => setPaymentMethod(newPaymentMethod)} value={paymentMethod}>
            {
              restaurantSupportedPaymentMethods.map((restaurantSupportedPaymentMethod) => (
                <RadioButton.Item key={restaurantSupportedPaymentMethod} label={Translator.t('paymentMethods.' + restaurantSupportedPaymentMethod)} value={restaurantSupportedPaymentMethod} color={theme.colors.primary} />
              ))
            }
          </RadioButton.Group>
        </Dialog.Content>
      ),
      validation: () => {
        return restaurantSupportedPaymentMethods.length > 0 && paymentMethod !== null && restaurantSupportedPaymentMethods.includes(paymentMethod);
      }
    },
    2: {
      content: (
        <Dialog.Content>
          <Slider label={tipPercentage + "%"} value={tipPercentage} onChange={(newTipPercentage) => setTipPercentage(newTipPercentage)} minimumValue={0} maximumValue={100} step={5} />
          <Caption>{capitalize(Translator.t('words.total'))}: { outputExchangedPriceAndTip(totalPrice, tip, Translator) }</Caption>
        </Dialog.Content>
      )
    }
  };
  const [stepIndexesToSkip, setStepIndexesToSkip] = useState([]);
  const stepIndexesWithoutSkipped = Object.keys(steps).filter((stepIndex) => !stepIndexesToSkip.includes(parseInt(stepIndex))).map((stepIndex) => parseInt(stepIndex));
  const indexOfCurrentStepInStepIndexesWithoutSkipped = stepIndexesWithoutSkipped.indexOf(currentStepIndex);
  const step = steps[currentStepIndex];
  const isLastStep = indexOfCurrentStepInStepIndexesWithoutSkipped === stepIndexesWithoutSkipped.length - 1;

  const styles = StyleSheet.create({
    buttonRow: {
      paddingLeft: 16,
      paddingRight: 16
    },
    buttonCol: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 16
    },
    buttonColStart: {
      paddingRight: 2,
    },
    buttonColMiddle: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    buttonColEnd: {
      paddingLeft: 2,
    },
  });

  useEffect(() => {
    const subscriber = Firebase.firestore().doc(props.paymentModal.order.order.store.path).onSnapshot((storeRef) => {
      if (storeRef.exists) {
        const store = storeRef.data();
        setStore(store);
      }
    });
    return () => {
      subscriber();
    };
  }, []);

  useEffect(() => {
    if (store === null) {
      return;
    }

    const supportedPaymentMethods = [];
    if (store.hasOwnProperty('isCashPaymentSupported') && store.isCashPaymentSupported === true) {
      supportedPaymentMethods.push("CASH");
    }
    if (store.hasOwnProperty('isCardTerminalPaymentSupported') && store.isCardTerminalPaymentSupported === true) {
      supportedPaymentMethods.push("CARD_TERMINAL");
    }
    if (store.hasOwnProperty('isCardAppPaymentSupported') && store.isCardAppPaymentSupported === true) {
      supportedPaymentMethods.push("CARD_ONLINE");
    }
    setRestaurantSupportedPaymentMethods(supportedPaymentMethods);
    setPaymentMethod(supportedPaymentMethods[0]);
  }, [store]);

  useEffect(() => {
    if (store === null) {
      return;
    }

    const newStepIndexesToSkip = [];

    if (!store.hasOwnProperty('isTipSupported') || store.isTipSupported === false) {
      newStepIndexesToSkip.push(2);
    }

    setStepIndexesToSkip(newStepIndexesToSkip);
  }, [store]);

  useEffect(() => {
    if (!stepIndexesWithoutSkipped.includes(currentStepIndex)) {
      if (stepIndexesWithoutSkipped.length > 0) {
        dispatch(changePaymentModal({
          order: paymentModal.order,
          step: stepIndexesWithoutSkipped[0],
        }));
      }
      else {
        dispatch(changePaymentModal(null));
      }
    }
  }, [stepIndexesToSkip]);

  useEffect(() => {
    setTip({});
    const newTip = {};
    Object.keys(totalPrice).forEach((currency) => {
      newTip[currency] = roundPrice(tipPercentage / 100 * totalPrice[currency]);
    });
    setTip(newTip);
  }, [tipPercentage]);

  const isStepValid = function () {
    const validation = step.hasOwnProperty('validation') && typeof step.validation === 'function' ? step.validation : null;
    if (validation !== null) {
      return validation();
    }
    else {
      return null;
    }
  };

  const previousStepButtonOnPress = function () {
    setPaymentModal({
      ...paymentModal,
      step: stepIndexesWithoutSkipped[indexOfCurrentStepInStepIndexesWithoutSkipped - 1]
    });
  };

  const nextStepButtonOnPress = function () {
    const stepValidation = isStepValid();
    if (stepValidation === null || stepValidation === true) {
      if (isLastStep) {
        console.log("PAY");
      }
      else {
        setPaymentModal({
          ...paymentModal,
          step: stepIndexesWithoutSkipped[indexOfCurrentStepInStepIndexesWithoutSkipped + 1]
        });
      }
    }
  };

  // console.log(paymentMethod);
  // console.log(tip);

  return (
    <Dialog visible={true} onDismiss={() => setPaymentModal(null)}>
      <Dialog.Title>{Translator.t('components.atoms.elements.paymentModal.step' + currentStepIndex + '.title')}</Dialog.Title>
      { step.content }
      <Dialog.Actions style={styles.buttonRow}>
        <Col style={[styles.buttonCol, styles.buttonColStart]} xs={6} sm={6} md={6} lg={6}>
          {
            indexOfCurrentStepInStepIndexesWithoutSkipped > 0 ? (
              <Button mode='outlined' onPress={previousStepButtonOnPress}>{Translator.t('words.previous')}</Button>
            ) : null
          }
        </Col>
        <Col style={[styles.buttonCol, styles.buttonColStart]} xs={6} sm={6} md={6} lg={6}>
          <Button mode='contained' onPress={nextStepButtonOnPress}>{ isLastStep ? Translator.t('words.pay') : Translator.t('words.next') }</Button>
        </Col>
      </Dialog.Actions>
    </Dialog>
  );
}

export default PaymentModal;
