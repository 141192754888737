import React from 'react';
import {View} from 'react-native';
import {Text, useTheme} from 'react-native-paper';
import {Slider as DefaultSlider} from '@miblanchard/react-native-slider';

function Slider(props) {
  const theme = useTheme();
  return (
    <View>
      <Text>{ props.label }</Text>
      <DefaultSlider
        value={props.value}
        onValueChange={props.onChange}
        minimumTrackTintColor={theme.colors.mutedPrimary}
        maximumTrackTintColor={theme.colors.background}
        thumbTintColor={theme.colors.primary}
        step={1}
        {...props}
      />
    </View>
  );
}

export default Slider;
