import * as Localization from 'expo-localization';

import Config from '../Config';
import Currencies from './Currencies';
import Timezones from './Timezones';
import Firebase from "./Firebase";
import Translator from "./Translator";

function parseDocPath(path) {
  const parsedDocPath = {};
  const pathParts = path.split('/');
  pathParts.forEach((pathPart, pathPartKey) => {
    if (pathPartKey % 2 === 0) {
      parsedDocPath[pathPart] = pathParts[pathPartKey + 1];
    }
  });
  return parsedDocPath;
}

function buildDocPath(parameters) {
  let docPath = '';
  for (const [parameterKey, parameter] of Object.entries(parameters)) {
    docPath += `${parameterKey}/${parameter}/`;
  }
  docPath = docPath.substring(0, docPath.length - 1);
  return docPath;
}

function parsePriceToFloat(priceAsString) {
  const price = parseFloat(priceAsString);
  if (isNaN(price)) {
    return 0;
  }

  return price;
}

function canPriceBeParseToFloat(priceAsString) {
  const price = parseFloat(priceAsString);
  return !isNaN(price);
}

function generateAcronym(string) {
  const matches = string.match(/\b(\w)/g);
  return matches.join('');
}

function isStringEmail(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

function prepareTheme(theme, colorScheme) {
  if (theme === 'auto') {
    return Config.supportedThemes.includes(colorScheme) ? colorScheme : 'light';
  }
  else {
    return Config.supportedThemes.includes(theme) ? theme : 'light';
  }
}

function prepareLocale(locale) {
  if (locale === 'auto') {
    return Config.supportedLocales.includes(Localization.locale.split('-')[0]) ? Localization.locale.split('-')[0] : 'bg';
  }
  else {
    return Config.supportedLocales.includes(locale) ? locale : 'bg';
  }
}

function prepareCurrency(currency) {
  const currencies = Currencies();
  if (currency === 'auto') {
    if (Localization.currency !== null) {
      return Object.keys(currencies.getAll('en')).includes(Localization.currency) ? Localization.currency : 'BGN';
    }
    else {
      return 'BGN';
    }
  }
  else {
    return Object.keys(currencies.getAll('en')).includes(currency) ? currency : 'BGN';
  }
}

function prepareTimezone(timezone) {
  const timezones = Timezones();
  if (timezone === 'auto') {
    return timezones.includes(Localization.timezone) ? Localization.timezone : 'Europe/Sofia';
  }
  else {
    return timezones.includes(timezone) ? timezone : 'Europe/Sofia';
  }
}

function getTranslationForLocale(translations, locale) {
  if (typeof translations !== 'object' || Object.keys(translations).length === 0) {
    return null;
  }
  return translations.hasOwnProperty(locale) ? translations[locale] : translations[Object.keys(translations)[0]];
}

function outputPrice(price, currency) {
  const currencies = new Currencies();
  return price + ' ' + currencies.get(currency).symbol_native;
}

function roundPrice(price) {
  return Math.round(price * 100) / 100;
}

function exchange(price, fromCurrency, toCurrency, rates) {
  if (fromCurrency === toCurrency) {
    return {
      price: price,
      currency: fromCurrency
    }
  }
  else {
    const fromCurrencyRates = rates.hasOwnProperty(fromCurrency) ? rates[fromCurrency] : null;
    if (fromCurrencyRates === null) {
      return {
        price,
        currency: fromCurrency
      };
    }
    const rate = fromCurrencyRates.hasOwnProperty(toCurrency) ? fromCurrencyRates[toCurrency] : null;
    if (rate === null) {
      return {
        price,
        currency: fromCurrency
      };
    }
    const exchangedPrice = price * rate;
    return {
      price: exchangedPrice,
      currency: toCurrency
    };
  }
}

function exchangeWithCount(price, count, fromCurrency, toCurrency, rates) {
  const exchangeResponse = exchange(price, fromCurrency, toCurrency, rates);
  const exchangedPrice = exchangeResponse.price;
  const exchangedCurrency = exchangeResponse.currency;
  let totalPrice = count * exchangedPrice;
  return {
    price: totalPrice,
    currency: exchangedCurrency
  };
}

function changeTimeZoneOfDate(date, timeZone) {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );
}

function getLastUpdateDateOfOrder(order) {
  if (order.hasOwnProperty('status') && Config.orderStatuses.includes(order.status)) {
    const propertyNameOfStatusChange = getPropertyNameOfStatusChange(order.status);
    return order.hasOwnProperty(propertyNameOfStatusChange) ? order[propertyNameOfStatusChange] : null;
  }
  else {
    return null;
  }
}

function getLastUpdateDateOfOrderProduct(orderProduct) {
  if (orderProduct.hasOwnProperty('status') && Config.orderProductStatuses.includes(orderProduct.status)) {
    const propertyNameOfStatusChange = getPropertyNameOfStatusChange(orderProduct.status);
    return orderProduct.hasOwnProperty(propertyNameOfStatusChange) ? orderProduct[propertyNameOfStatusChange] : null;
  }
  else {
    return null;
  }
}

function translateOrderStatus(status, Translator) {
  return Translator.t('order.statuses.' + status);
}

function translateOrderProductStatus(status, Translator) {
  return Translator.t('orderProduct.statuses.' + status);
}

function getPropertyNameOfStatusChange(status) {
  const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );

  return snakeToCamel(status) + "At";
}

function outputExchangedPrice(exchangedPrice) {
  return Object.keys(exchangedPrice).map((currency) => outputPrice(exchangedPrice[currency], currency)).join(" + ");
}

function outputExchangedPriceAndTip(exchangedPrice, tip, Translator) {
  return Object.keys(exchangedPrice).map((currency) => outputPrice(exchangedPrice[currency], currency) + " (" + Translator.t('words.price') + ") + " + outputPrice(tip[currency], currency) + " (" + Translator.t('words.tip') + ")").join(" + ")
}

export {
  parseDocPath, buildDocPath, parsePriceToFloat, canPriceBeParseToFloat, generateAcronym, isStringEmail, prepareTheme, prepareLocale, prepareCurrency, prepareTimezone, getTranslationForLocale, outputPrice, roundPrice, exchange, exchangeWithCount, changeTimeZoneOfDate, getLastUpdateDateOfOrder, getLastUpdateDateOfOrderProduct, translateOrderStatus, translateOrderProductStatus, getPropertyNameOfStatusChange, outputExchangedPrice, outputExchangedPriceAndTip
};
