import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import { View, StyleSheet } from 'react-native';

import Firebase from '../../../modules/Firebase';
import {
  buildDocPath, exchange,
  exchangeWithCount,
  getTranslationForLocale,
  parseDocPath,
  prepareLocale, roundPrice
} from "../../../modules/Helper";
import Translator from "../../../modules/Translator";
import Section from "../../atoms/layouts/Section";
import ProductList from "../lists/ProductList";
import TagsFilter from "../../atoms/inputs/TagsFilter";
import {Divider, Searchbar} from "react-native-paper";

function OrderCreateForm(props) {
  const navigation = useNavigation();
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const currency = useSelector((state) => state.intl.currency);
  const rates = useSelector((state) => state.config.rates);
  const [brand, setBrand] = useState(null);
  const [menu, setMenu] = useState(null);
  const [sections, setSections] = useState([]);
  const [products, setProducts] = useState([]);
  const [tagsFilter, setTagsFilter] = useState({});
  const [searchString, setSearchString] = useState('');
  const onSearchChange = function (newSearchString) {
    setSearchString(newSearchString);
  };

  const tableDocPath = props.hasOwnProperty('path') && props.path !== null ? props.path : null;
  if (tableDocPath === null) {
    alert(Translator.t('components.organisms.forms.OrderCreateForm.error.invalidTable'));
    return null;
  }

  const tableDocParsedPath = parseDocPath(tableDocPath);
  if (!tableDocParsedPath.hasOwnProperty('company') || !tableDocParsedPath.hasOwnProperty('brand') || !tableDocParsedPath.hasOwnProperty('store') || !tableDocParsedPath.hasOwnProperty('table')) {
    alert(Translator.t('components.organisms.forms.OrderCreateForm.error.invalidTable'));
    return null;
  }

  useEffect(() => {
    Firebase.firestore().collection('company').doc(tableDocParsedPath.company).collection('brand').doc(tableDocParsedPath.brand).get().then((brandDoc) => {
      if (brandDoc.exists) {
        const brandDocParsedPath = parseDocPath(brandDoc.ref.path);
        const brand = brandDoc.data();
        navigation.setOptions({
          title: brand.name
        });
        setBrand({
          cover: brand.hasOwnProperty('logo') ? brand.logo : null,
          name: brand.name,
          activeMenuPath: brand.hasOwnProperty('activeMenu') ? brand.activeMenu : null,
          companyId: brandDocParsedPath.company,
          brandId: brandDoc.id,
        });
      }
      else {
        alert(Translator.t('components.organisms.forms.OrderCreateForm.error.invalidBrand'));
        return null;
      }
    });
  }, []);

  useEffect(() => {
    if (brand === null || !brand.hasOwnProperty('activeMenuPath')) {
      return;
    }
    const activeMenuDocPath = brand.activeMenuPath;
    if (activeMenuDocPath !== null) {
      Firebase.firestore().doc(activeMenuDocPath).get().then((menuDoc) => {
        if (menuDoc.exists) {
          const menuDocParsedPath = parseDocPath(menuDoc.ref.path);
          const menu = menuDoc.data();
          setMenu({
            name: menu.waiterName,
            companyId: menuDocParsedPath.company,
            brandId: menuDocParsedPath.brand,
            menuId: menuDoc.id,
          });
        }
        else {
          alert(Translator.t('components.organisms.forms.OrderCreateForm.error.invalidMenu'));
          return null;
        }
      });
    }
    else {
      alert(Translator.t('components.organisms.forms.OrderCreateForm.error.invalidActiveMenu'));
      return null;
    }
  }, [brand]);

  useEffect(() => {
    if (menu === null) {
      return;
    }
    Firebase.firestore().collection('company').doc(menu.companyId).collection('brand').doc(menu.brandId).collection('menu').doc(menu.menuId).collection('section').get().then((sectionsRef) => {
      const sectionDocs = sectionsRef.docs;
      const sections = [];
      sectionDocs.forEach((sectionDoc) => {
        const sectionDocParsedPath = parseDocPath(sectionDoc.ref.path);
        const section = sectionDoc.data();
        sections.push({
          companyId: sectionDocParsedPath.company,
          brandId: sectionDocParsedPath.brand,
          menuId: sectionDocParsedPath.menu,
          sectionId: sectionDoc.id,
          ...section
        });
      });
      setSections(sections);
    });
  }, [menu]);

  useEffect(() => {
    if (sections.length === 0) {
      return;
    }
    const unsubscribes = [];
    const sectionDocPaths = [];
    sections.forEach((section) => {
      const sectionDocPath = buildDocPath({
        company: section.companyId,
        brand: section.brandId,
        menu: section.menuId,
        section: section.sectionId
      });
      sectionDocPaths.push(sectionDocPath);
    });
    unsubscribes.push(Firebase.firestore().collection('product').where('sections', 'array-contains-any', sectionDocPaths).onSnapshot((productsRef) => {
      const productDocs = productsRef.docs;
      const products = [];
      const tagsFilter = {};
      productDocs.forEach((productDoc) => {
        const product = productDoc.data();
        const tags = product.hasOwnProperty('tags') && Array.isArray(product.tags) ? product.tags : [];
        if (product.hasOwnProperty('isAvailable') && typeof product.isAvailable === 'boolean' && product.isAvailable === true) {
          tags.forEach((tag) => tagsFilter[tag] = false);
        }
        products.push({
          productId: productDoc.id,
          ...product
        });
      });
      setProducts(products);
      setTagsFilter(tagsFilter);
    }));
    return () => unsubscribes.forEach(unsubscribe => unsubscribe());
  }, [sections]);

  if (sections.length === 0 || products.length === 0) {
    return null;
  }

  const sectionsOutputData = sections.map((section) => {
    return {
      ...section,
      name: getTranslationForLocale(section.nameTranslations, Translator.locale),
      description: getTranslationForLocale(section.hasOwnProperty('descriptionTranslations') ? section.descriptionTranslations : null, Translator.locale),
    };
  });

  let productsOutputData = [];

  products.map((product) => {
    const tags = product.hasOwnProperty('tags') && Array.isArray(product.tags) ? product.tags : [];
    const selectedTagsFilter = Object.keys(tagsFilter).filter((tag) => tagsFilter[tag]);
    const foundTagsAfterFilter = tags.some(tag => selectedTagsFilter.includes(tag));
    const totalPriceExchangeResponse = exchange(product.price, product.currency, currency, rates);
    const transformedProduct = {
      ...product,
      disabled: !(product.hasOwnProperty('isAvailable') && typeof product.isAvailable === 'boolean' && product.isAvailable === true),
      cover: product.hasOwnProperty('image') && typeof product.image === 'string' ? product.image : null,
      name: getTranslationForLocale(product.nameTranslations, Translator.locale),
      description: getTranslationForLocale(product.hasOwnProperty('descriptionTranslations') ? product.descriptionTranslations : null, Translator.locale),
      enableBuy: true,
      enableView: true,
      tableDocPath,
      totalPrice: {
        currency: totalPriceExchangeResponse.currency,
        price: roundPrice(totalPriceExchangeResponse.price),
      },
    };
    if (selectedTagsFilter.length > 0 && !foundTagsAfterFilter) {
      return;
    }
    productsOutputData.push(transformedProduct);
  });

  productsOutputData = productsOutputData.filter((product) => {
    const name = product.hasOwnProperty('name') && typeof product.name === 'string' ? product.name : null;
    const description = product.hasOwnProperty('description') && typeof product.description === 'string' ? product.description : null;
    if (name !== null && name.toUpperCase().includes(searchString.toUpperCase())) {
      return true;
    }
    if (description !== null && description.toUpperCase().includes(searchString.toUpperCase())) {
      return true;
    }
    return false;
  });

  return (
    <View>
      <View style={styles.filterContainer}>
        <Searchbar placeholder={ Translator.t('components.molecules.inputs.SearchInput.label') } onChangeText={onSearchChange} value={searchString} />
      </View>
      <View>
        <TagsFilter tags={tagsFilter} onPress={(tag) => {
          const newTagsFilter = Object.assign({}, tagsFilter);
          newTagsFilter[tag] = !newTagsFilter[tag];
          setTagsFilter(newTagsFilter);
        }}/>
      </View>
      <Divider style={styles.dividerContainer}/>
      <View>
        {
          sectionsOutputData.map((section) => {
            const sectionDocPath = buildDocPath({
              company: section.companyId,
              brand: section.brandId,
              menu: section.menuId,
              section: section.sectionId
            });
            const sectionProducts = productsOutputData.filter((product) => product.sections.includes(sectionDocPath));
            if (sectionProducts.filter((product) => product.disabled === false).length === 0) {
              return null;
            }
            return (
              <Section key={sectionDocPath} title={section.name} description={section.description} hidden={sectionProducts.length === 0}>
                <ProductList products={sectionProducts} />
              </Section>
            );
          })
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  dividerContainer: {
    marginTop: 25,
    marginBottom: 25
  },
  filterContainer: {
    marginBottom: 10
  }
});

export default OrderCreateForm;
