import React, {useEffect, useState} from 'react';
import DashboardLayout from "../atoms/layouts/DashboardLayout";
import View from "../atoms/layouts/View";
import Translator from "../../modules/Translator";
import Col from "../atoms/layouts/Col";
import Section from "../atoms/layouts/Section";
import {useDispatch, useSelector} from "react-redux";
import {
  buildDocPath,
  exchangeWithCount,
  getTranslationForLocale,
  outputExchangedPrice,
  prepareLocale,
  roundPrice
} from "../../modules/Helper";
import ProductList from "../organisms/lists/ProductList";
import Firebase from "../../modules/Firebase";
import Footer from "../atoms/layouts/Footer";
import Row from "../atoms/layouts/Row";
import {Divider, IconButton, Menu, Title, useTheme} from "react-native-paper";
import {StyleSheet, View as DefaultView} from "react-native";
import {capitalize} from "stringulation";
import {changeOrderModal} from "../../redux/reducers/PageReducer";
import {useNavigation} from "@react-navigation/native";

function MyCart() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = StyleSheet.create({
    tableSectionShortcutsContainer: {
      marginTop: 50
    },
    sectionHeadingContentContainer: {
      marginTop: 10
    }
  });
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const currency = useSelector((state) => state.intl.currency);
  const timezone = useSelector((state) => state.intl.timezone);
  const user = useSelector((state) => state.auth.user);
  const activeOrder = useSelector((state) => state.session.activeOrder);
  const rates = useSelector((state) => state.config.rates);
  const [products, setProducts] = useState([]);
  const [tables, setTables] = useState({});
  const [isOrderMenuOpened, setIsOrderMenuOpened] = useState({});
  const openSectionShortcutsMenu = (path) => {
    setIsOrderMenuOpened((isOrderMenuOpened) => {
      const newIsOrderMenuOpened = Object.assign({}, isOrderMenuOpened);
      if (newIsOrderMenuOpened.hasOwnProperty(path)) {
        newIsOrderMenuOpened[path] = !(newIsOrderMenuOpened[path]);
      }
      else {
        newIsOrderMenuOpened[path] = true;
      }
      return newIsOrderMenuOpened;
    });
  };
  const closeSectionShortcutsMenu = (path) => {
    setIsOrderMenuOpened((isOrderMenuOpened) => {
      const newIsOrderMenuOpened = Object.assign({}, isOrderMenuOpened);
      if (newIsOrderMenuOpened.hasOwnProperty(path)) {
        newIsOrderMenuOpened[path] = !(newIsOrderMenuOpened[path]);
      }
      else {
        newIsOrderMenuOpened[path] = false;
      }
      return newIsOrderMenuOpened;
    });
  };
  const finishOrder = (tablePath, table, tableProducts, totalPrice) => {
    dispatch(changeOrderModal({
      tablePath,
      table,
      tableProducts,
      totalPrice,
      step: 1,
      onSuccess: () => {
        navigation.navigate('Orders', {
          screen: 'MyOrders'
        })
      }
    }));
  };
  const deleteActiveOrder = (tablePath) => {
    const newActiveOrder = Object.assign({}, activeOrder);
    newActiveOrder['products'] = newActiveOrder.products.filter((newActiveOrderProduct) => {
      return newActiveOrderProduct.table.path !== tablePath;
    });
    Firebase.firestore().collection('user').doc(user).set({
      activeOrder: newActiveOrder
    }, {
      merge: true
    }).catch((error) => {
      alert(error.message);
    });
  };
  const changeActiveOrderProductCount = (newCount, productId) => {
    const path = buildDocPath({
      product: productId,
    });
    const newActiveOrderProducts = activeOrder.products.map(object => ({ ...object }));
    newActiveOrderProducts.forEach((activeOrderProduct, activeOrderProductIndex) => {
      const productRef = activeOrderProduct.product;
      if (productRef.path === path) {
        if (newCount === 0) {
          newActiveOrderProducts.splice(activeOrderProductIndex, 1);
        }
        else {
          newActiveOrderProducts[activeOrderProductIndex] = {
            ...newActiveOrderProducts[activeOrderProductIndex],
            count: newCount
          };
        }
      }
    });
    Firebase.firestore().collection('user').doc(user).set({
      activeOrder: {
        ...activeOrder,
        products: newActiveOrderProducts
      }
    }, {
      merge: true
    }).catch((error) => {
      alert(error.message);
    });
  };

  useEffect(() => {
    setProducts([]);
    const unsubscribers = [];
    if (activeOrder !== null) {
      activeOrder.products.forEach((activeOrderProduct) => {
        const table = activeOrderProduct.table;
        const lastAddedAt = activeOrderProduct.lastAddedAt;
        const count = activeOrderProduct.hasOwnProperty('count') ? activeOrderProduct.count : 1;
        const productRef = activeOrderProduct.product;
        unsubscribers.push(productRef.onSnapshot((productDoc) => {
          if (productDoc.exists) {
            const product = productDoc.data();
            setProducts((products) => {
              const existingProductsWithoutNewProduct = products.filter((product) => {
                return product.productId !== productDoc.id;
              });
              const totalPriceExchangeResponse = exchangeWithCount(product.price, count, product.currency, currency, rates);
              const newProducts = [
                {
                  ...product,
                  disabled: !(product.hasOwnProperty('isAvailable') && product.isAvailable === true),
                  count,
                  table,
                  lastAddedAt,
                  productId: productDoc.id,
                  cover: product.hasOwnProperty('image') && typeof product.image === 'string' ? product.image : null,
                  name: getTranslationForLocale(product.nameTranslations, Translator.locale),
                  description: getTranslationForLocale(product.hasOwnProperty('descriptionTranslations') ? product.descriptionTranslations : null, Translator.locale),
                  enableBuy: false,
                  enableView: false,
                  countChangeCallback: (newCount) => {
                    changeActiveOrderProductCount(newCount, productDoc.id);
                  },
                  totalPrice: {
                    currency: totalPriceExchangeResponse.currency,
                    price: roundPrice(totalPriceExchangeResponse.price),
                  },
                },
                ...existingProductsWithoutNewProduct,
              ];
              newProducts.sort((a, b) => {
                return b.lastAddedAt - a.lastAddedAt;
              });
              return newProducts;
            });
          }
        }));
      });
    }
    return () => {
      unsubscribers.forEach((unsubscriber) => unsubscriber());
    };
  }, [activeOrder]);

  useEffect(() => {
    setTables({});
    const unsubscribers = [];
    products.forEach((product) => {
      unsubscribers.push(product.table.parent.parent.onSnapshot((storeDoc) => {
        if (storeDoc.exists) {
          const store = storeDoc.data();
          setTables((tables) => {
            return {
              ...tables,
              [product.table.path]: {
                store
              }
            };
          });
        }
      }));
    });
    return () => {
      unsubscribers.forEach((unsubscriber) => unsubscriber());
    };
  }, [products]);

  return (
    <DashboardLayout>
      <View>
        <Row>
          {
            Object.keys(tables).map((tablePath) => {
              const table = tables[tablePath];
              const tableProducts = products.filter((product) => product.table.path === tablePath);
              const totalPrices = tableProducts.map((product) => product.totalPrice);
              const totalPrice = {};
              totalPrices.forEach((totalPriceItem) => {
                if (totalPrice.hasOwnProperty(totalPriceItem.currency)) {
                  totalPrice[totalPriceItem.currency] = roundPrice(totalPrice[totalPriceItem.currency] += totalPriceItem.price);
                }
                else {
                  totalPrice[totalPriceItem.currency] = roundPrice(totalPriceItem.price);
                }
              });
              return (
                <Section key={tablePath}
                 headingContent={
                   <DefaultView style={styles.sectionHeadingContentContainer}>
                     <Title>
                       {capitalize(Translator.t('words.total'))}: { outputExchangedPrice(totalPrice) }
                     </Title>
                   </DefaultView>
                 }
                 title={table.store.name} // Translator.t('pages.MyCart.content.products.title')
                 description={Translator.t('pages.MyCart.content.products.description')}
                 headingShortcut={
                   <Menu
                     style={styles.tableSectionShortcutsContainer}
                     visible={isOrderMenuOpened.hasOwnProperty(tablePath) && isOrderMenuOpened[tablePath] === true}
                     onDismiss={() => (closeSectionShortcutsMenu(tablePath))}
                     anchor={<IconButton icon="dots-vertical" color={theme.colors.primary} size={30} onPress={() => (openSectionShortcutsMenu(tablePath))} />}>
                     <Menu.Item icon='send' onPress={() => {
                       closeSectionShortcutsMenu(tablePath);
                       finishOrder(tablePath, table, tableProducts, totalPrice);
                     }} title={capitalize(Translator.t('words.send'))} />
                     <Menu.Item icon='trash-can' onPress={() => {
                       closeSectionShortcutsMenu(tablePath);
                       deleteActiveOrder(tablePath);
                     }} title={capitalize(Translator.t('pages.MyCart.content.shortcut.deleteActiveOrder'))} />
                     <Divider/>
                     <Menu.Item icon='window-close' onPress={() => (openSectionShortcutsMenu(tablePath))} title={capitalize(Translator.t('words.close'))} />
                   </Menu>
                 }
                >
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <ProductList products={tableProducts} />
                  </Col>
                </Section>
              );
            })
          }
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Footer/>
          </Col>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default MyCart;
