import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-native-paper';

import View from '../atoms/layouts/View';
import Col from '../atoms/layouts/Col';
import Section from '../atoms/layouts/Section';
import Translator from '../../modules/Translator';
import DashboardLayout from '../atoms/layouts/DashboardLayout';
import { prepareLocale } from '../../modules/Helper';
import QrCodeForm from "../organisms/forms/QrCodeForm";

function Dashboard(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <DashboardLayout>
      <View>
        <Section title={Translator.t('pages.Dashboard.content.newOrder.title')} description={Translator.t('pages.Dashboard.content.newOrder.description')}>
          <Col xs={12} sm={12} md={6} lg={4}>
            <QrCodeForm/>
          </Col>
        </Section>
        <Section title={Translator.t('pages.Dashboard.content.myAccount.title')} description={Translator.t('pages.Dashboard.content.myAccount.description')} hideBottomDivider={true}>
          <Col xs={12} sm={12} md={6} lg={4}>
            <Button mode='contained' onPress={() => props.navigation.navigate('Account', {
              screen: 'MyAccount'
            })}>{Translator.t('words.view')}</Button>
          </Col>
        </Section>
      </View>
    </DashboardLayout>
  );
}

export default Dashboard;
