import React, { useState, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';
import { Provider as ReactNativePaperProvider, Portal } from 'react-native-paper';
import { useColorScheme } from 'react-native-appearance';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { useFonts, Inter_300Light, Inter_400Regular, Inter_500Medium, Inter_600SemiBold } from '@expo-google-fonts/inter';

import { LightTheme, DarkTheme } from './src/modules/Theme';
import Config from './src/Config';
import Linking from './src/modules/Linking';
import MainTabNavigation from './src/components/MainTabNavigation';
import Firebase from './src/modules/Firebase';
import { changeUser } from './src/redux/reducers/AuthReducer';
import Loading from './src/components/atoms/elements/Loading';
import { changeCurrency, changeLocale, changeTimezone } from './src/redux/reducers/IntlReducer';
import Modal from './src/components/atoms/elements/Modal';
import { changeTheme } from './src/redux/reducers/DesignReducer';
import {buildDocPath, prepareTheme} from './src/modules/Helper';
import {changeActiveOrder, changeOrders} from "./src/redux/reducers/SessionReducer";
import {changeRates} from "./src/redux/reducers/ConfigReducer";
import PaymentModal from "./src/components/atoms/elements/PaymentModal";
import {changePaymentModal, changeOrderModal} from "./src/redux/reducers/PageReducer";
import OrderModal from "./src/components/atoms/elements/OrderModal";

// import * as FirebaseAnalytics from 'expo-firebase-analytics';
// FirebaseAnalytics.logEvent('app_loaded');

// TODO Add delete action when status is waiting acceptance
// TODO Add tip property to order from beginning
// TODO On click on send order on cart page, check if exists order of this table and ask if to add products to it or create new one
// TODO When deleting section, from cloud functions I have to select the products, where has reference with the deleted section, and delete the reference.
// TODO Make the header fixed (on web)

function App() {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const paymentModal = useSelector((state) => state.page.paymentModal);
  const orderModal = useSelector((state) => state.page.orderModal);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const colorScheme = useColorScheme();
  const theme = useSelector((state) => state.design.theme);
  const locale = useSelector((state) => state.intl.locale);
  const currency = useSelector((state) => state.intl.currency);
  const timezone = useSelector((state) => state.intl.timezone);
  let [fontsLoaded] = useFonts({
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold
  });

  useEffect(() => {
    let userUnsubscribe = null;
    const authUnsubscribe = Firebase.auth().onAuthStateChanged((user) => {
      dispatch(changeUser(user !== null ? user.uid : null));
      if (user !== null) {
        userUnsubscribe = Firebase.firestore().collection('user').doc(user.uid).onSnapshot((userDoc) => {
          const user = userDoc.data();
          dispatch(changeTheme(user.hasOwnProperty('theme') ? user.theme : Config.defaultTheme));

          dispatch(changeLocale(user.hasOwnProperty('locale') ? user.locale : Config.defaultLocale));
          dispatch(changeCurrency(user.hasOwnProperty('currency') ? user.currency : Config.defaultCurrency));
          dispatch(changeTimezone(user.hasOwnProperty('timezone') ? user.timezone : Config.defaultTimezone));

          dispatch(changeActiveOrder(user.hasOwnProperty('activeOrder') && user.activeOrder !== null ? user.activeOrder : null));
        });
      }
      else {
        dispatch(changeTheme(Config.defaultTheme));

        dispatch(changeLocale(Config.defaultLocale));
        dispatch(changeCurrency(Config.defaultCurrency));
        dispatch(changeTimezone(Config.defaultTimezone));
      }
      if (isAuthLoading) {
        setIsAuthLoading(false);
      }
    });
    return () => {
      authUnsubscribe();
      if (userUnsubscribe !== null) {
        userUnsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const unsubscriber = Firebase.firestore().collection('config').doc('rates').onSnapshot((ratesDoc) => {
      const rates = ratesDoc.data();
      dispatch(changeRates(rates));
    });
    return () => {
      unsubscriber();
    };
  }, []);

  useEffect(() => {
    const unsubscribers = [];
    if (user !== null) {
      unsubscribers.push(Firebase.firestore().collection('order').where('user.path', '==', buildDocPath({
        user: user
      })).where('status', '!=', Config.orderStatuses[Config.orderStatuses.length - 1]).onSnapshot((ordersRef) => {
        const orderDocs = ordersRef.docs;
        const newOrders = [];
        orderDocs.forEach((orderDoc) => {
          const order = orderDoc.data();
          newOrders.push({
            path: orderDoc.ref.path,
            order
          });
        });
        dispatch(changeOrders(newOrders));
      }));
    }
    else {
      dispatch(changeOrders([]));
    }
    return () => {
      unsubscribers.forEach((unsubscriber) => unsubscriber());
    };
  }, [user]);

  const themeObject = prepareTheme(theme, colorScheme) === 'dark' ? DarkTheme : LightTheme;

  if (isAuthLoading || !fontsLoaded) {
    return <Loading theme={themeObject} />;
  }

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.keyboardAvoidingView}>
      <StatusBar style="light" />
      <ReactNativePaperProvider theme={themeObject}>
        <Portal>
          <Modal/>
          <PaymentModal paymentModal={paymentModal} setPaymentModal={(newPaymentModal) => dispatch(changePaymentModal(newPaymentModal))} />
          <OrderModal orderModal={orderModal} setOrderModal={(newOrderModal) => dispatch(changeOrderModal(newOrderModal))} />
        </Portal>
        <NavigationContainer linking={Linking} theme={themeObject} fallback={<Loading theme={themeObject} />}>
          <MainTabNavigation />
        </NavigationContainer>
      </ReactNativePaperProvider>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
});

export default App;
