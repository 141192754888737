import React  from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Title } from 'react-native-paper';
import Chip from '../elements/Chip';

function TagsView(props) {
  const transformValueInTagsArray = function (value) {
    if (Array.isArray(value)) {
      return value;
    }
    else if (typeof value === 'string') {
      let tags = value.trim().split(',');
      tags = tags.filter((tag) => tag.length > 0);
      return tags;
    }
    else {
      return [];
    }
  };
  const tags = props.hasOwnProperty('tags') ? transformValueInTagsArray(props.tags) : [];
  const onPress = props.hasOwnProperty('onPress') ? props.onPress : null;

  return (
    <View style={styles.chipContainer}>
      {
        tags.length === 0 ? (<Title>---</Title>) : null
      }
      {tags.map((tag, tagKey) => (
        <Chip key={tagKey} size={30} onPress={onPress !== null ? function () {
          onPress(tag);
        } : null}>
          <Text>
            #
            {tag}
          </Text>
        </Chip>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
});

export default TagsView;
