import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from "react-redux";
import QrCodeScanner from "../atoms/elements/QrCodeScanner";

function QrScan(props) {
  const qrScans = useSelector((state) => state.page.qrScans);
  const qrScanId = props.route.params.id;
  const onScanned = qrScans.hasOwnProperty(qrScanId) && qrScans[qrScanId].hasOwnProperty('onScanned') ? qrScans[qrScanId].onScanned : function () {};

  return (
    <View style={styles.container}>
      <View style={styles.qrScanContainer}>
        <QrCodeScanner style={styles.qrScan} onScanned={onScanned} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  qrScanContainer: {
    width: '100%',
    height: '90%',
  },
  qrScan: {
    ...StyleSheet.absoluteFillObject,
  }
});

export default QrScan;
