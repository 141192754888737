import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
const queryString = require('query-string');
import { useNavigation } from "@react-navigation/native";

import QrCodeInput from "../../molecules/inputs/QrCodeInput";
import {createQrScan} from "../../../redux/reducers/PageReducer";
import {buildDocPath, parseDocPath, prepareLocale} from "../../../modules/Helper";
import Translator from "../../../modules/Translator";

function QrCodeForm() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const onScanned = function (url) {
    const query = queryString.parseUrl(url).query;
    const tableDocPath = typeof query.path === 'string' ? query.path : null;
    if (tableDocPath === null) {
      alert(Translator.t('components.organisms.forms.QrCodeForm.error.invalidQrScan'));
      return;
    }
    const parsedTableDocPath = parseDocPath(tableDocPath);
    const requiredKeys = ['company', 'brand', 'store', 'table'];
    if (requiredKeys.every((requiredKey) => parsedTableDocPath.hasOwnProperty(requiredKey))) {
      navigation.navigate('Home', {
        screen: 'OrderCreateItem',
        params: {
          path: buildDocPath(parsedTableDocPath),
        }
      })
    }
    else {
      alert(Translator.t('components.organisms.forms.QrCodeForm.error.invalidQrScan'));
      return;
    }
  };

  useEffect(() => {
    dispatch(createQrScan({
      id: 'qrCodeFormQrCode',
      qrScan: {
        onScanned: onScanned
      },
    }));
  }, []);

  return (
    <View>
      <QrCodeInput id="qrCodeFormQrCode" />
    </View>
  );
}

export default QrCodeForm;
