import React, {useEffect, useState} from 'react';
import {Button, Dialog, Title} from "react-native-paper";
import Translator from "../../../modules/Translator";
import {
  buildDocPath, changeTimeZoneOfDate,
  exchange,
  exchangeWithCount, getTranslationForLocale,
  parseDocPath,
  prepareLocale,
  roundPrice
} from "../../../modules/Helper";
import {useSelector} from "react-redux";
import {StyleSheet, View, ScrollView} from "react-native";
import Slider from "../inputs/Slider";
import Col from "../layouts/Col";
import Firebase from "../../../modules/Firebase";
import Config from "../../../Config";

function OrderModal(props) {
  const orderModal = props.hasOwnProperty('orderModal') ? props.orderModal : null;
  const setOrderModal = props.hasOwnProperty('setOrderModal') ? props.setOrderModal : null;
  if (orderModal === null || setOrderModal === null || !orderModal.hasOwnProperty('step') || !orderModal.hasOwnProperty('tablePath') || !orderModal.hasOwnProperty('table') || !orderModal.hasOwnProperty('tableProducts') || !orderModal.hasOwnProperty('totalPrice') || !orderModal.hasOwnProperty('onSuccess')) {
    return null;
  }
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const activeOrder = useSelector((state) => state.session.activeOrder);
  const currency = useSelector((state) => state.intl.currency);
  const timezone = useSelector((state) => state.intl.timezone);
  const rates = useSelector((state) => state.config.rates);
  const [timeToDeliver, setTimeToDeliver] = useState({});
  const [restaurantOrderMinTime, setRestaurantOrderMinTime] = useState(0);
  const styles = StyleSheet.create({
    buttonRow: {
      paddingLeft: 16,
      paddingRight: 16
    },
    buttonCol: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 16
    },
    buttonColStart: {
      paddingRight: 2,
    },
    buttonColMiddle: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    buttonColEnd: {
      paddingLeft: 2,
    },
    tableProductItemContainer: {
      marginTop: 10,
      marginBottom: 10
    },
    scrollViewContainer: {
      maxHeight: 500
    }
  });
  // If needed: optimize this method to take data from some component states
  const sendOrderOfTable = (tablePath) => {
    if (orderModal.tableProducts.filter((product) => product.hasOwnProperty('isAvailable') && product.isAvailable === true).length === 0) {
      return;
    }
    const parsedTableDocPath = parseDocPath(tablePath);
    const fetchData = [];
    const tableRef = Firebase.firestore().doc(tablePath);
    const storeRef = Firebase.firestore().doc(buildDocPath({
      company: parsedTableDocPath.company,
      brand: parsedTableDocPath.brand,
      store: parsedTableDocPath.store,
    }));
    const brandRef = Firebase.firestore().doc(buildDocPath({
      company: parsedTableDocPath.company,
      brand: parsedTableDocPath.brand,
    }));
    const userRef = Firebase.firestore().doc(buildDocPath({
      user: user
    }));
    fetchData.push({
      ref: tableRef.get(),
    });
    fetchData.push({
      ref: storeRef.get(),
    });
    fetchData.push({
      ref: brandRef.get(),
    });
    fetchData.push({
      ref: userRef.get(),
    });
    orderModal.tableProducts.forEach((product) => {
      if (product.table.path === tablePath) {
        const productPath = buildDocPath({
          product: product.productId,
        });
        const productRef = Firebase.firestore().doc(productPath);
        fetchData.push({
          ref: productRef.get(),
          additional: {
            count: product.count,
          }
        });
      }
    });
    Promise.all(fetchData.map((fetchDataObj) => fetchDataObj.ref)).then((docs) => {
      const tableDoc = docs.shift();
      const storeDoc = docs.shift();
      const brandDoc = docs.shift();
      const userDoc = docs.shift();
      if (tableDoc.exists && storeDoc.exists && brandDoc.exists && userDoc.exists) {
        const table = tableDoc.data();
        const store = storeDoc.data();
        const brand = brandDoc.data();
        const user = userDoc.data();
        const orderProducts = [];
        docs.forEach((productDoc, productDocIndex) => {
          const productDocIndexInFetchData = productDocIndex + 4;
          const product = productDoc.data();
          const fetchDataAdditional = fetchData[productDocIndexInFetchData].additional;
          if (product.isAvailable === true) {
            const exchangedPriceExchangeResponse = exchange(product.price, product.currency, currency, rates);
            const totalExchangedPriceExchangeResponse = exchangeWithCount(product.price, fetchDataAdditional.count, product.currency, currency, rates);
            const orderProductItems = {};
            for (let i = 0 ; i < fetchDataAdditional.count ; i++) {
              orderProductItems[i] = {
                status: Config.orderProductStatuses[0],
                waitingAcceptanceAt: changeTimeZoneOfDate(new Date(), Config.systemTimezone),
                acceptedAt: null,
                preparedAt: null,
                servedAt: null,
                timeToDeliver: timeToDeliver[productDoc.ref.path][i]
              };
            }
            orderProducts.push({
              exchangedPrice: {
                currency: exchangedPriceExchangeResponse.currency,
                price: roundPrice(totalExchangedPriceExchangeResponse.price),
              },
              totalExchangedPrice: {
                currency: totalExchangedPriceExchangeResponse.currency,
                price: roundPrice(totalExchangedPriceExchangeResponse.price),
              },
              path: productDoc.ref.path,
              currency: product.currency,
              description: getTranslationForLocale(product.hasOwnProperty('descriptionTranslations') ? product.descriptionTranslations : null, Translator.locale),
              name: getTranslationForLocale(product.nameTranslations, Translator.locale),
              price: product.price,
              waiterName: product.waiterName,
              items: orderProductItems
            });
          }
        });
        const totalPrices = orderProducts.map((orderProduct) => orderProduct.totalExchangedPrice);
        const totalPrice = {};
        totalPrices.forEach((totalPriceItem) => {
          if (totalPrice.hasOwnProperty(totalPriceItem.currency)) {
            totalPrice[totalPriceItem.currency] = roundPrice(totalPrice[totalPriceItem.currency] += totalPriceItem.price);
          }
          else {
            totalPrice[totalPriceItem.currency] = roundPrice(totalPriceItem.price);
          }
        });
        const order = {
          user: {
            path: userDoc.ref.path,
            currency: currency,
            email: user.email,
            locale: Translator.locale,
            timezone: timezone,
          },
          brand: {
            path: brandDoc.ref.path,
            name: brand.name,
          },
          store: {
            path: storeDoc.ref.path,
            name: store.name,
            location: store.location,
          },
          table: {
            path: tableDoc.ref.path,
            waiterName: table.waiterName,
          },
          products: orderProducts,
          status: Config.orderStatuses[0],
          waitingAcceptanceAt: changeTimeZoneOfDate(new Date(), Config.systemTimezone),
          acceptedAt: null,
          paidAt: null,
          exchangedPrice: totalPrice
        };
        Firebase.firestore().collection('order').doc().set(order).then(() => {
          const newProducts = [];
          const productIdsToRemove = order.products.map((orderProduct) => parseDocPath(orderProduct.path).product);
          if (activeOrder !== null) {
            const oldProducts = activeOrder.products.map(object => ({ ...object }));
            oldProducts.forEach((oldProduct) => {
              const productId = parseDocPath(oldProduct.product.path).product;
              if (!productIdsToRemove.includes(productId)) {
                newProducts.push(oldProduct);
              }
            });
          }
          const newActiveOrder = newProducts.length > 0 ? {
            products: newProducts,
          } : null;
          userRef.set({
            activeOrder: newActiveOrder
          }, {
            merge: true
          }).then(() => {
            alert(Translator.t('pages.MyCart.content.sendOrder.success.title'));
            orderModal.onSuccess();
          }).catch((error) => {
            alert(error.message);
          });
        }).catch((error) => {
          alert(error.message);
        });
      }
    }).catch((error) => {
      alert(error.message);
    });
  };

  useEffect(() => {
    setTimeToDeliver(() => {
      const newTimeToDeliver = {};
      orderModal.tableProducts.forEach((tableProduct) => {
        const productPath = buildDocPath({
          product: tableProduct.productId
        });
        const tableProductTimeToDeliver = {};
        const count = tableProduct.count;
        for (let i = 0; i < count; i++) {
          tableProductTimeToDeliver[i] = 0;
        }
        newTimeToDeliver[productPath] = tableProductTimeToDeliver;
      });
      return newTimeToDeliver;
    });
    setRestaurantOrderMinTime(0);
  }, []);

  const steps = {};
  steps[1] = (
    <Dialog.ScrollArea>
      <ScrollView style={styles.scrollViewContainer}>
      {
        Object.keys(timeToDeliver).map((productPath, productIndex) => {
          const productTimeToDeliver = timeToDeliver[productPath];
          return (
            <View key={productPath}>
              {
                Object.keys(productTimeToDeliver).map((productItemIndex) => {
                  const productItemTimeToDeliver = productTimeToDeliver[productItemIndex];
                  return (
                    <View key={productPath + "_" + productItemIndex} style={styles.tableProductItemContainer}>
                      <Title>{ orderModal.tableProducts.filter((tableProduct) => buildDocPath({
                        product: tableProduct.productId
                      }) === productPath)[0].name }{ Object.keys(productTimeToDeliver).length > 1 ? " " + (parseInt(productItemIndex) + 1) : null }</Title>
                      <Slider label={Translator.t('words.in(time)') + " " + productItemTimeToDeliver + " " + Translator.t('words.min(minutes)')} value={productItemTimeToDeliver} onChange={(newProductItemTimeToDeliver) => setTimeToDeliver((timeToDeliver) => {
                        const newTimeToDeliver = Object.assign({}, timeToDeliver);
                        newTimeToDeliver[productPath][productItemIndex] = newProductItemTimeToDeliver[0];
                        return newTimeToDeliver;
                      })} minimumValue={0} maximumValue={60} step={1} trackClickable={false} />
                    </View>
                  );
                })
              }
            </View>
          );
        })
      }
      </ScrollView>
    </Dialog.ScrollArea>
  );

  return (
    <Dialog visible={true} onDismiss={() => setOrderModal(null)}>
      <Dialog.Title>{Translator.t('components.atoms.elements.orderModal.step' + orderModal.step + '.title')}</Dialog.Title>
      { steps[orderModal.step] }
      <Dialog.Actions style={styles.buttonRow}>
        <Col style={[styles.buttonCol, styles.buttonColStart]} xs={6} sm={6} md={6} lg={6}>
          {
            orderModal.step > 1 ? (
              <Button mode='outlined' onPress={() => setOrderModal({
                ...orderModal,
                step: orderModal.step - 1
              })}>{Translator.t('words.previous')}</Button>
            ) : null
          }
        </Col>
        <Col style={[styles.buttonCol, styles.buttonColEnd]} xs={6} sm={6} md={6} lg={6}>
          {
            orderModal.step < Object.keys(steps).length ? (
              <Button mode='contained' onPress={() => setOrderModal({
                ...orderModal,
                step: orderModal.step + 1
              })}>{Translator.t('words.next')}</Button>
            ) : null
          }
          {
            orderModal.step === Object.keys(steps).length ? (
              <Button mode='contained' onPress={() => {
                sendOrderOfTable(orderModal.tablePath);
                setOrderModal(null);
              }}>{Translator.t('words.send')}</Button>
            ) : null
          }
        </Col>
      </Dialog.Actions>
    </Dialog>
  );
}

export default OrderModal;
