import { createSlice } from '@reduxjs/toolkit';

export const ConfigSlice = createSlice({
  name: 'config',
  initialState: {
    rates: {},
  },
  reducers: {
    changeRates: (state, action) => {
      state.rates = action.payload;
    },
  },
});

export const {
  changeRates,
} = ConfigSlice.actions;

export default ConfigSlice.reducer;
