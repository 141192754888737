import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, HelperText, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function QrScanInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const mode = props.hasOwnProperty('mode') ? props.mode : 'contained';
  const disabled = props.hasOwnProperty('disabled') ? props.disabled : false;
  const navigation = useNavigation();
  const theme = useTheme();
  const qrScanId = props.id;
  let label = props.hasOwnProperty('label') ? props.label : Translator.t('components.atoms.inputs.QrScanInput.label');
  const errors = props.hasOwnProperty('errors') ? props.errors : {};
  const errorMessages = [];
  const styles = StyleSheet.create({
    container: {
      marginBottom: 10,
    },
    buttonError: {
      borderColor: theme.colors.error
    },
    buttonLabelError: {
      color: theme.colors.error
    }
  });

  for (const [errorKey, error] of Object.entries(errors)) {
    errorMessages.push(Translator.t('errors.' + errorKey));
  }

  return (
    <View style={styles.container}>
      <Button
        mode={mode}
        onPress={() => {
          navigation.navigate('Home', {
            screen: 'QrScan',
            params: {
              id: qrScanId,
            }
          });
        }}
        style={errorMessages.length > 0 ? [styles.buttonError] : []}
        labelStyle={errorMessages.length > 0 ? [styles.buttonLabelError] : []}
        {...props}
        disabled={disabled}
      >
        { label }
      </Button>
      {
        errorMessages.length > 0 ? (
          errorMessages.map(function (errorMessage, errorMessageIndex) {
            return (
              <HelperText key={errorMessageIndex} type="error">{errorMessage}</HelperText>
            );
          })
        ) : null
      }
    </View>
  );
}

export default QrScanInput;
