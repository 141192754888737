const Config = {
  baseUrl: 'https://order-mobile.smart-take.com',
  protocolId: 'ordermobilesmarttake',
  defaultLocale: 'auto',
  supportedLocales: ['auto', 'en', 'de', 'it', 'bg', 'fr', 'ru'],
  defaultTheme: 'auto',
  supportedThemes: ['auto', 'light', 'dark'],
  defaultTimezone: 'auto',
  defaultCurrency: 'auto',
  defaultLocation: {
    latitude: 42.698334,
    longitude: 23.319941
  },
  supportedRights: {
    1: 'viewer',
    4: 'editor',
    7: 'admin'
  },
  defaultRights: 7,
  dimensions: {
    sm: 418,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1400,
  },
  systemTimezone: "Europe/Sofia",
  orderProductStatuses: [
    "WAITING_ACCEPTANCE",
    "ACCEPTED",
    "PREPARED",
    "SERVED"
  ],
  orderStatuses: [
    "WAITING_ACCEPTANCE",
    "ACCEPTED",
    "PAID"
  ],
  supportedPaymentMethods: [
    "CASH",
    "CARD_ONLINE",
    "CARD_TERMINAL"
  ]
};

export default Config;
